import { Pools } from '@/types/pools';

const pools: Pools = {
  IdsMap: {
    staBAL:
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063',
    bbAaveUSD: {
      v1: '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
      v2: '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d',
    },
    veBAL: '0x23a8a6e5d468e7acf4cc00bd575dbecf13bc7f78000100000000000000000015',
  },
  Pagination: {
    PerPage: 100,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x47a8f3fbcc8060c33942f00a4134a1488d093d5f00010000000000000000001a',
    '0x5b2dcd660d8bd78b5242e76a2f5103a5e16d5313000200000000000000000019',
    '0x86c8e2f771e186724f622562e0d871e401271fb000010000000000000000001b',
    '0xe41a30824496dd6e2ab2ed7d751849bb475c439c00020000000000000000001d',
    '0x370284e0dd6ff98aae3167766ba6e4c23bdfc44500020000000000000000001c',
    '0xf46c7ee1b8c3414b175bea40b8f16cfc8a05709c000200000000000000000018',
    '0xb9b725888c41d4f7bdddf0e05dae03b91e4a4a5f000200000000000000000017',
    '0xcb9eb3f264be622a6d707947765db5c79d969ca7000000000000000000000009',
    '0x0da797cd401b59871ddcdf018a73dc56ae27a6d1000200000000000000000011',
    '0x74970b267a572dfd2997a350e5adf047336bcb3f000100000000000000000023',
  ],
  IncludedPoolTypes: [
    'Weighted',
    'Stable',
    'MetaStable',
    'LiquidityBootstrapping',
    'Investment',
    'StablePhantom',
    'ComposableStable',
    'FX',
  ],
  Stable: {
    AllowList: [
      '0xceecce984f498ee00832670e9ca6d372f6ce155a000200000000000000000014',
      '0xb427111143052b6856aaf2a65c9b44191167e4b6000000000000000000000044',
      '0x46218d1962f39d96b9295a86d01849f36bf52a3e000000000000000000000047',
    ],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [
      '0x5f5f57abf920a88e77513a28d41df39a0e0b4b6a000200000000000000000035',
      '0x293bbbef6087f681a8110f08bbdedadd13599fc3000200000000000000000007',
      '0x2ec560ba933e8994482af76d63378bdc5354402100020000000000000000002a',
      '0xe370d4d0727d4e9b70db1a2f7d2efd1010ff1d6d000200000000000000000021',
      '0xa83f9fa9b51fc26e9925a07bc3375617b473e051000200000000000000000005',
      '0xec0fac82d2aac483871874407d25614cf6dd9ce5000200000000000000000030',
      '0x6e7c170f95aba8ffbccaf5155706be0682cc5bd900020000000000000000002b',
      '0xae4d320cc49f57a2bf6f628a749f5aba19d2c7ea000200000000000000000031',
      '0xf53a4381f3a4f08726970bc6bb23c4ee3ef101ed000200000000000000000033',
      '0xa6ac7ce1d7aefd7abd06d93e6b89489aedf03d14000200000000000000000029',
      '0xd8e9e1916a4d98fb0dc6db725a8c8c2af08a329b00020000000000000000000f',
      '0x4652df40de98c20e73d88983a8b74e4aa014bdf3000200000000000000000032',
      '0x3c5b26cde094fe7e5c75651a7ab5e9520ec06e5700020000000000000000002e',
      '0x9cd44e44e8a61bc7dc34b04c762a3c0137a3707c000200000000000000000002',
      '0x0fb037f35f5a00f66e3fc9a29f2dca59c12bfc5f000200000000000000000027',
      '0x89cc63050ade84bffafd7ec84d24fc0feb5f96c9000200000000000000000020',
      '0x454adaa07eec2c432c0df4379a709b1fa4c800ed000200000000000000000016',
      '0xa215a58225b344cbb62fcf762e8e884dbedfbe58000200000000000000000006',
      '0x0993fa12d3256e85da64866354ec3532f187e178000200000000000000000008',
      '0x435de7d1c13cfec80997510bd38bcb45282e6a0f000200000000000000000034',
      '0x244caf21eaa7029db9d6b42ddf2d95800a2f5eb500020000000000000000000a',
      '0x4200333dc021ea5fb1050b8e4f8f3ed7cb1d22ed00020000000000000000000c',
      '0xd88a378abfe6b6e232525dfb03fbe01ecc863c10000200000000000000000004',
      '0xf0b6cf745afe642c4565165922ad62d6a93857c100020000000000000000000e',
      '0x9127442d0a170cc0efb5d164582948f21c37312e000200000000000000000028',
      '0xe03dc509b05e7d80ee863c0d4144ddc5a03e652300020000000000000000002d',
      '0x20169fb10ca8635f4f5c487f7ce811aa5ae08a7300020000000000000000002f',
      '0xb3a04902b78fbe61185b766866193630db4db8a300020000000000000000000d',
      '0x089352cdc9d23ab490317ad5aa838e4e0780165d00020000000000000000002c',
      '0xceecce984f498ee00832670e9ca6d372f6ce155a000200000000000000000014',
      '0xb615c4febfe88b2c93774bcff32b5a5be25e66ec000200000000000000000037',
      '0x8afc3d8ddbcc76de12a219f6ec7226e7a7c190fc000200000000000000000036',
      '0x80cba127d820f0138d252009b7a073955a4e7d75000200000000000000000039',
      '0x614c884caa52a231d74a3553fdddb0b7be53032300020000000000000000003d',
      '0xc38d30794c44c39fe6077dc80704d2a501f2a75800020000000000000000003b',
      '0xdab85176672835e1efc37729540ebc24e18a742500020000000000000000003c',
      '0xe02e913a4f2e5b87b8b953ee3a26f9b4919a0306000200000000000000000040',
      '0x381d8ba80b9bb2b7ae09b895bcb4b0ec8d54703f000200000000000000000042',
      '0xd2aa7c896eedc8d85a5f46b1c9fc3edbf5a7b91d000100000000000000000043',
      '0x5f8e74ebd221342f17e8c5fb1c50089f51cd863a000200000000000000000045',
      '0xe84e5418432beeeb543bbcdf9fa54fd7ea3aa65b000200000000000000000049',
      '0x46218d1962f39d96b9295a86d01849f36bf52a3e000000000000000000000047',
      '0xf45db6b9ea5a531fbde51e3f2915979e9210246d00020000000000000000004a',
      '0x7c2bb9c37036912228f36449d14f0f7e19c861f200020000000000000000004b',
      '0x6f6b70c5b9731fe5e4b1ade898dbb50a23dcb61600020000000000000000004c',
      '0x1ed3bbef2de9fb1b50827a27ee591f09c1ebda9a00020000000000000000004d',
      '0xde394782872384aec124ffc034c065efe1979d8d00020000000000000000004f',
      '0x01aa7c6ae7d654a669919be766132fe3eafc8ba900020000000000000000004e',
      '0x35ff4a0b0d1bf6550e7c4c548f93afec489ae50e000200000000000000000050',
      '0x23a15f4a31c567619388b3234a73921d72cabe3a000200000000000000000053',
    ],
  },
  Factories: {
    '0x98b5584a0c40f2c50cba0adb40e51a8cad79b9ac': 'weightedPool', // weighted pool v4
    '0x5fbee5bb890cd665f0b05fefd9b0e207328415f9': 'weightedPool', // weighted pool v1
    '0xc92dc3d6547625fa9c5b1837edf632943a5c4ceb': 'oracleWeightedPool', // oracle weighted pool v1
    '0x16506f300a7d22b16d4b2d01568d565ec840c753': 'stablePool', // stable pool v2
    // '0xA408376E84DedD19616e8Cd79753eb4B594acd85': 'stablePool', // stable pool v4
    '0xcacd11cdd35d2842e6ce16fb76920021d8bab58b': 'composableStablePool', // composable stable pool v6
  },
  Stakable: {
    VotingGaugePools: [
      '0x5f5f57abf920a88e77513a28d41df39a0e0b4b6a000200000000000000000035',
      '0x293bbbef6087f681a8110f08bbdedadd13599fc3000200000000000000000007',
      '0x2ec560ba933e8994482af76d63378bdc5354402100020000000000000000002a',
      '0xe370d4d0727d4e9b70db1a2f7d2efd1010ff1d6d000200000000000000000021',
      '0xa83f9fa9b51fc26e9925a07bc3375617b473e051000200000000000000000005',
      '0xec0fac82d2aac483871874407d25614cf6dd9ce5000200000000000000000030',
      '0x6e7c170f95aba8ffbccaf5155706be0682cc5bd900020000000000000000002b',
      '0xae4d320cc49f57a2bf6f628a749f5aba19d2c7ea000200000000000000000031',
      '0xf53a4381f3a4f08726970bc6bb23c4ee3ef101ed000200000000000000000033',
      '0xa6ac7ce1d7aefd7abd06d93e6b89489aedf03d14000200000000000000000029',
      '0xd8e9e1916a4d98fb0dc6db725a8c8c2af08a329b00020000000000000000000f',
      '0x4652df40de98c20e73d88983a8b74e4aa014bdf3000200000000000000000032',
      '0x3c5b26cde094fe7e5c75651a7ab5e9520ec06e5700020000000000000000002e',
      '0x9cd44e44e8a61bc7dc34b04c762a3c0137a3707c000200000000000000000002',
      '0x0fb037f35f5a00f66e3fc9a29f2dca59c12bfc5f000200000000000000000027',
      '0x89cc63050ade84bffafd7ec84d24fc0feb5f96c9000200000000000000000020',
      '0x454adaa07eec2c432c0df4379a709b1fa4c800ed000200000000000000000016',
      '0xa215a58225b344cbb62fcf762e8e884dbedfbe58000200000000000000000006',
      '0x0993fa12d3256e85da64866354ec3532f187e178000200000000000000000008',
      '0x435de7d1c13cfec80997510bd38bcb45282e6a0f000200000000000000000034',
      '0x244caf21eaa7029db9d6b42ddf2d95800a2f5eb500020000000000000000000a',
      '0x4200333dc021ea5fb1050b8e4f8f3ed7cb1d22ed00020000000000000000000c',
      '0xd88a378abfe6b6e232525dfb03fbe01ecc863c10000200000000000000000004',
      '0xf0b6cf745afe642c4565165922ad62d6a93857c100020000000000000000000e',
      '0x9127442d0a170cc0efb5d164582948f21c37312e000200000000000000000028',
      '0xe03dc509b05e7d80ee863c0d4144ddc5a03e652300020000000000000000002d',
      '0x20169fb10ca8635f4f5c487f7ce811aa5ae08a7300020000000000000000002f',
      '0xb3a04902b78fbe61185b766866193630db4db8a300020000000000000000000d',
      '0x089352cdc9d23ab490317ad5aa838e4e0780165d00020000000000000000002c',
      '0xceecce984f498ee00832670e9ca6d372f6ce155a000200000000000000000014',
      '0xb615c4febfe88b2c93774bcff32b5a5be25e66ec000200000000000000000037',
      '0x8afc3d8ddbcc76de12a219f6ec7226e7a7c190fc000200000000000000000036',
      '0x80cba127d820f0138d252009b7a073955a4e7d75000200000000000000000039',
      '0x614c884caa52a231d74a3553fdddb0b7be53032300020000000000000000003d',
      '0xc38d30794c44c39fe6077dc80704d2a501f2a75800020000000000000000003b',
      '0xdab85176672835e1efc37729540ebc24e18a742500020000000000000000003c',
      '0xe02e913a4f2e5b87b8b953ee3a26f9b4919a0306000200000000000000000040',
      '0x381d8ba80b9bb2b7ae09b895bcb4b0ec8d54703f000200000000000000000042',
      '0xd2aa7c896eedc8d85a5f46b1c9fc3edbf5a7b91d000100000000000000000043',
      '0x5f8e74ebd221342f17e8c5fb1c50089f51cd863a000200000000000000000045',
      '0xe84e5418432beeeb543bbcdf9fa54fd7ea3aa65b000200000000000000000049',
      '0x46218d1962f39d96b9295a86d01849f36bf52a3e000000000000000000000047',
      '0xf45db6b9ea5a531fbde51e3f2915979e9210246d00020000000000000000004a',
      '0x7c2bb9c37036912228f36449d14f0f7e19c861f200020000000000000000004b',
      '0x6f6b70c5b9731fe5e4b1ade898dbb50a23dcb61600020000000000000000004c',
      '0x1ed3bbef2de9fb1b50827a27ee591f09c1ebda9a00020000000000000000004d',
      '0xde394782872384aec124ffc034c065efe1979d8d00020000000000000000004f',
      '0x01aa7c6ae7d654a669919be766132fe3eafc8ba900020000000000000000004e',
      '0x35ff4a0b0d1bf6550e7c4c548f93afec489ae50e000200000000000000000050',
      '0x23a15f4a31c567619388b3234a73921d72cabe3a000200000000000000000053',
    ],
    AllowList: [],
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [],
  Deprecated: {},
  BrandedRedirect: {},
};

export default pools;
